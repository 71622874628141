//@import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import '../src/styles/material3.css';
//@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';

/* Dialog overlay for click-outside handling */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-overlay .e-dialog {
  z-index: 1001;
  position: relative;
  background-color: #fff;
  /* Adding box shadow to make dialog stand out */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

/* Ensure no pointer events on dialog overlay itself */
.e-dlg-overlay {
  pointer-events: none !important;
}

/* But allow pointer events on dialog content */
.e-dlg-container, .e-dialog {
  pointer-events: auto !important;
}